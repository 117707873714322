import React from "react";
import { Text, useTheme } from 'native-base';

export default function Paragraph({text, ml=5, mr=5, mt=5, mb=0, ...rest}) {
    const { colors } = useTheme();
    return (
        <Text
            {...rest}
            fontSize={{
                base: "18",
                md: "20",
                lg: "22",
                xl: "26"
            }}
            color={colors.black[800]}
            ml={ml} mr={mr} mt={mt} mb={mb}
        >
            {text}
        </Text>
    );
}
