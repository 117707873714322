import React from "react";
import { useTheme } from 'native-base';
import { FlatGrid } from "react-native-super-grid";

export default function SearchResultList({data, dataKey, space, renderItem}) {
    const theme = useTheme();
    return (
        <FlatGrid
            nestedScrollEnabled={true}
            itemDimension={250}
            data={data}
            keyExtractor={index => index[dataKey]}
            spacing={theme.space[space]}
            renderItem={renderItem}
        />
    );
}
