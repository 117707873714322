import React from "react";
import { Center, Image } from 'native-base';
import urls from "../../services/urls";

export default function CenteredImage({source, image, width, height, ml=5, mr=5, mt=5, mb=0}) {
    const imageSource = source ? source : {
        'uri': urls.imageBase + '/' + image,
    }
    return (
        <Center height={height / 2} width="100%">
            <Image
                ml={ml} mr={mr} mt={mt} mb={mb}
                source={imageSource}
                height={height / 2}
                width="100%"
                style={{ aspectRatio: width/height }}
                resizeMode= "contain"
                alt=" "
            />
        </Center>
    );
}
