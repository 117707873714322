import React, { useCallback, useState } from "react";
import Screen from "./Screen";
import Title from "../content_blocks/Title";
import { Box, Button, Center, CheckIcon, Container, FormControl, Input, Select, TextArea, VStack, WarningOutlineIcon } from "native-base";
import { useQuery } from "react-query";
import { getPlacesForSelect } from "../../services/winery/placeApi";
import appConfig from "../../config/app.json";
import axios from "axios";
import { registrate } from "../../services/winery/registrationApi";
import Paragraph from "../content_blocks/Paragraph";

export default function RegistrationForm() {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        wineryName: '',
        wineryPlace: null,
        note: '',
    });
    
    const [errors, setErrors] = useState({});
    const [serverError, setServerError] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);

    const { data: places=[] } = useQuery(['/place/all', 'getPlacesForSelect'], () => getPlacesForSelect());  
    
    const validate = () => {
        let errors = {};
        if (formData.name == null) {
            errors = { ...errors,
                name: 'A név megadás kötelező'
            };
        } else if (formData.name.length < 3) {
            errors = { ...errors,
                name: 'A név hossza legalább 3 karakter kell legyen'
            };
        } else if (formData.name.length < 3) {
            errors = { ...errors,
                name: 'A név hossza legalább 3 karakter kell legyen'
            };
        } else if (formData.name.length > 191) {
            errors = { ...errors,
                name: 'A név hossza legfeljebb 191 karakter lehet'
            };
        }

        if (formData.email == null) {
            errors = { ...errors,
                email: 'Az e-mail cím megadás kötelező'
            };
        } else if (formData.email.length < 3) {
            errors = { ...errors,
                email: 'Az e-mail cím hossza legalább 3 karakter kell legyen'
            };
        } else if (formData.email.length < 3) {
            errors = { ...errors,
                email: 'Az e-mail cím hossza legalább 3 karakter kell legyen'
            };
        } else if (formData.email.length > 191) {
            errors = { ...errors,
                name: 'Az e-mail cím hossza legfeljebb 191 karakter lehet'
            };
        }

        if (formData.wineryName == null) {
            errors = { ...errors,
                wineryName: 'A pincészet név megadás kötelező'
            };
        } else if (formData.wineryName.length < 3) {
            errors = { ...errors,
                wineryName: 'A pincészet név hossza legalább 3 karakter kell legyen'
            };
        } else if (formData.wineryName.length < 3) {
            errors = { ...errors,
                wineryName: 'A pincészet név hossza legalább 3 karakter kell legyen'
            };
        } else if (formData.email.length > 191) {
            errors = { ...errors,
                name: 'Az pincészet név hossza legfeljebb 191 karakter lehet'
            };
        }

        if (formData.note && formData.note.length > 2000) {
            errors = { ...errors,
                note: 'A megjegyzés hossza legfeljebb 2000 karakter lehet'
            };
        }

        setErrors(errors);
        return Object.keys(errors).length == 0;
    };


    const startRegistration = useCallback(() => {
        if (isSubmitting) {
            return;
        }
        setIsSubmitting(true);
        const cancellationTokenSource = axios.CancelToken.source();
        registrate(formData, cancellationTokenSource.token)
        .then((response) => {
            setIsSuccess(true);
            setIsSubmitting(false);
        }).catch((error) => {
            setIsSubmitting(false);
            if (axios.isCancel(error)) {
                return;
            }
            setServerError(error.note);
            console.log(error.note);
        });
    
        return () => {
            cancellationTokenSource.cancel();
        }
    }, [isSubmitting, formData, serverError]);

    const onSubmit = (data) => {
        if (validate()) {
            startRegistration(formData);
        }
    };

    return (
        <Screen showRegistration={false}>
            {
                isSuccess
                ?
                <>
                    <Title text={'Sikeres regisztráció'}/>
                    <Paragraph text={'Munkatársunk hamarosan felveszi önnel a kapcsolatot a megadott e-mail címen.'} textAlign={'center'}/>
                </>
                :
                <>
                    <Title text={appConfig.appName + ' - tartalomkezelői regisztráció'}/>
                    <Box mt={5}>
                        <Center flex={1}>
                            <VStack width="90%" mx="3" maxW="350px">
                                <FormControl isRequired isInvalid={'name' in errors}>
                                    <FormControl.Label _text={{
                                        bold: true
                                    }}>Név</FormControl.Label>
                                    <Input placeholder="Írja be a nevét" value={formData.name} onChangeText={value => setFormData({ ...formData, name: value })} />
                                    {'name' in errors && <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>{errors.name}</FormControl.ErrorMessage>}
                                </FormControl>

                                <FormControl isRequired isInvalid={'email' in errors} mt={4}>
                                    <FormControl.Label _text={{
                                        bold: true
                                    }}>E-mail cím</FormControl.Label>
                                    <Input placeholder="Írja be az e-mail címét" value={formData.email} onChangeText={value => setFormData({ ...formData, email: value })} />
                                    {'email' in errors && <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>{errors.email}</FormControl.ErrorMessage>}
                                </FormControl>

                                <FormControl isRequired isInvalid={'wineryName' in errors} mt={4}>
                                    <FormControl.Label _text={{
                                        bold: true
                                    }}>Pincészet név</FormControl.Label>
                                    <Input placeholder="Írja be a regisztrációhoz kapcsolódó pincészet nevét" value={formData.wineryName} onChangeText={value => setFormData({ ...formData, wineryName: value })} />
                                    {'wineryName' in errors && <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>{errors.wineryName}</FormControl.ErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={'wineryPlace' in errors} mt={4}>
                                    <FormControl.Label>Pincészet települése</FormControl.Label>
                                    <Select minWidth="200" placeholder="Válassza ki a pincészet települését" defaultValue={'null'} selectedValue={formData.wineryPlace} onValueChange={value => setFormData({ ...formData, wineryPlace: value })} _selectedItem={{
                                        bg: "teal.600",
                                        endIcon: <CheckIcon size={5} />
                                    }} mt="1">
                                        {
                                            [{id: 'null', name: '-'}].concat((places ?? [])).map(place => <Select.Item key={place.id} label={place.name} value={place.id} />)
                                        }
                                    </Select>
                                    {'wineryPlace' in errors && <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>{errors.wineryPlace}</FormControl.ErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={'note' in errors} mt={4}>
                                    <FormControl.Label _text={{
                                        bold: true
                                    }}>Megjegyzés</FormControl.Label>
                                    <TextArea placeholder="Megjegyzés" value={formData.note} onChangeText={value => setFormData({ ...formData, note: value })} />
                                    {'note' in errors && <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>{ errors.note }</FormControl.ErrorMessage>}
                                </FormControl>

                                <FormControl isInvalid={serverError} mt={6}>
                                    <Button isLoading={isSubmitting} isLoadingText={'Regisztráció végrehajtása'} onPress={onSubmit}>
                                        Regisztráció
                                    </Button>
                                    <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>{serverError}</FormControl.ErrorMessage>
                                </FormControl>

                            </VStack>
                        </Center>
                    </Box>
                </>
            }
            
        </Screen>
    );
}

