import React from "react";
import { Box, HStack, Text, VStack } from 'native-base';
import Subtitle from "../content_blocks/Subtitle";

export default function PropertiesCard({rows, title, ml=5, mr=5, mt=5, mb=0, headerColumnWidth=200, dataColumnWidth=200, outerSpacing=5, innerSpacing=2}) {
    return (
        <>
            <Subtitle text={title} ml={ml} mr={mr} mt={mt} mb={0}/>
            <Box bg="white" rounded="lg" borderColor="black" p={outerSpacing} style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}} ml={ml} mr={mr} mt={outerSpacing} mb={mb} elevation={2}>
                <VStack space={innerSpacing}>
                    {
                        rows.map((row) => 
                            <HStack key={row.header} flexWrap={'wrap'}>
                                <Text bold flexGrow="1" style={{ width: headerColumnWidth }} fontSize={{
                                    base: "18",
                                    md: "20",
                                    lg: "22",
                                    xl: "26"
                                }}>{row.header}</Text>
                                <Text flexGrow="2" style={{ width: dataColumnWidth }} fontSize={{
                                    base: "18",
                                    md: "20",
                                    lg: "22",
                                    xl: "26"
                                }}>{row.data}</Text>
                            </HStack>
                        )
                    }
                </VStack>
            </Box>
        </>
    );
}



