import React from "react";
import { Button, extendTheme, NativeBaseProvider, StatusBar } from "native-base";
import { NavigationContainer, DefaultTheme as NavigationDefaultTheme } from '@react-navigation/native';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import MainScreens from "./components/screens/MainScreens";
import { createStackNavigator } from '@react-navigation/stack';
import Page from "./components/screens/Page";
import appConfig from "./config/app.json";
import reactNavigationConfig from "./config/reactNavigation.json";
import Wine from "./components/screens/Wine";
import Winery from "./components/screens/Winery";
import Place from "./components/screens/Place";
import { QueryClient, QueryClientProvider } from 'react-query';
import HomeButton from "./components/screens/HomeButton";
import RegistrationForm from "./components/screens/RegistrationForm";

const Stack = createStackNavigator();
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
        },
    }
});

export default function App() {

    const theme = extendTheme({
        colors: {
            primary: {
                50: '#f7fee7',
                100: '#ecfccb',
                200: '#d9f99d',
                300: '#bef264',
                400: '#a3e635',
                500: '#84cc16',
                600: '#65a30d',
                700: '#4d7c0f',
                800: '#3f6212',
                900: '#365314',
            },
        },
        config: {
            initialColorMode: 'light',
        },
    });

    const navigationTheme = {
        ...NavigationDefaultTheme,
        colors: {
            ...NavigationDefaultTheme.colors,
            background: theme.colors.white
        },
    };

    return (
        <SafeAreaProvider>
            <StatusBar backgroundColor={theme.colors.white} barStyle="dark-content"/>
            <NativeBaseProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <NavigationContainer
                        linking={{
                            enabled: true,
                            config: reactNavigationConfig,
                        }}
                        theme={navigationTheme}
                        documentTitle={{
                            formatter: (options, route) => {
                                return `${options?.title ?? route?.name} - ${appConfig.appName}`;
                            } 
                        }}
                    >
                        <Stack.Navigator>
                            <Stack.Screen name="MainScreens" component={MainScreens} options={{ headerShown: false }}/>
                            <Stack.Screen name="Wine" component={Wine} options={{ title: "Betöltés...", headerRight: () => <HomeButton/> }}/>
                            <Stack.Screen name="Winery" component={Winery} options={{ title: "Betöltés...", headerRight: () => <HomeButton/> }}/>
                            <Stack.Screen name="Place" component={Place} options={{ title: "Betöltés...", headerRight: () => <HomeButton/> }}/>
                            <Stack.Screen name="Page" component={Page} options={{ title: "Betöltés...", headerRight: () => <HomeButton/> }}/>
                            <Stack.Screen name="RegistrationForm" component={RegistrationForm} options={{ title: "Regisztráció", headerRight: () => <HomeButton/> }}/>
                        </Stack.Navigator>
                    </NavigationContainer>
                </QueryClientProvider>
            </NativeBaseProvider>
        </SafeAreaProvider>
    );
}
