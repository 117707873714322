import React from "react";
import Screen from "./Screen";
import { useQuery } from "react-query";
import ContentBlocks from "../content_blocks/ContentBlocks";
import { useNavigation } from '@react-navigation/native';
import CenteredImage from "../content_blocks/CenteredImage";
import Title from "../content_blocks/Title";
import WineCarousel from "../content_blocks/WineCarousel";
import WineryCarousel from "../content_blocks/WineryCarousel";
import { getPlace } from "../../services/winery/placeApi";
import PropertiesCard from "../cards/PropertiesCard";

export default function Place({route}) {
    const navigation = useNavigation();
    const { data } = useQuery(['/place', route.params.slug, 'getPlace'], () => getPlace(route.params.slug), {
        onSuccess: (data) => {
            navigation.setOptions({title: data?.title});
        },
        onError: () => {
            navigation.setOptions({title: 'Hiányzó oldal'});
        }
    });
    const rows = data != null ? [
        data.population !== null && {
            header: 'Lakosság szám',
            data: data.population + ' fő',
        },
        data.area !== null && {
            header: 'Terület',
            data: data.area + ' km²',
        },
        data.populationDensity !== null && {
            header: 'Népsűrűség',
            data: data.populationDensity + ' fő/km²',
        },
        data.vineyardArea !== null && {
            header: 'Szőlő terület mérete',
            data: data.vineyardArea + ' km²',
        },
        data.typicalGrapeTypes !== null && {
            header: 'Jellemző szőlő fajták',
            data: data.typicalGrapeTypes,
        },
    ].filter(d => d) : [];
    return (
        <Screen>
            {
                data &&
                <>
                    <CenteredImage image={data.image} width={data.imageWidth} height={data.imageHeight} />
                    <Title text={data.title}/>
                    <ContentBlocks contentBlocks={data.contentBlocks} mb={0}/>
                    <PropertiesCard rows={rows} title={'Jellemzők'}/>
                    <WineCarousel mt={5} wines={data.wines}/>
                    <WineryCarousel wineries={data.wineries} mt={0}/>
                </>
            }
        </Screen>
    );
}
