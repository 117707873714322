import React from "react";
import Screen from "./Screen";
import { useQuery } from "react-query";
import ContentBlocks from "../content_blocks/ContentBlocks";
import { getWinery } from "../../services/winery/wineryApi";
import { useNavigation } from '@react-navigation/native';
import CenteredImage from "../content_blocks/CenteredImage";
import Title from "../content_blocks/Title";
import ReferenceBox from "../content_blocks/ReferenceBox";
import WineCarousel from "../content_blocks/WineCarousel";
import PropertiesCard from "../cards/PropertiesCard";
import { Link } from "native-base";

export default function Winery({route}) {
    const navigation = useNavigation();
    const { data } = useQuery(['/winery', route.params.slug, 'getWinery'], () => getWinery(route.params.slug), {
        onSuccess: (data) => {
            navigation.setOptions({title: data?.title});
        },
        onError: () => {
            navigation.setOptions({title: 'Hiányzó oldal'});
        }
    });
    const rows = data != null ? [
        data.yearOfFoundation !== null && {
            header: 'Alapítás éve',
            data: data.yearOfFoundation,
        },
        data.winemaker !== null && {
            header: 'Borász',
            data: data.winemaker,
        },
        data.website !== null && {
            header: 'Weboldal',
            data: <Link href={data.website} isExternal target="_blank" >
                {data.website}
            </Link>,
        },
    ].filter(d => d) : [];
    return (
        <Screen>
            {
                data &&
                <>
                    <CenteredImage image={data.image} width={data.imageWidth} height={data.imageHeight} />
                    <Title text={data.title}/>
                    <ContentBlocks contentBlocks={data.contentBlocks} mb={0}/>
                    <PropertiesCard rows={rows} title={'Jellemzők'}/>
                    <WineCarousel wines={data.wines} mt={5}/>
                    <ReferenceBox {...data.place} typeTitle={'Település'} mt={0} mb={5} onPress={() => navigation.push('Place', {slug: data.place.slug})}/>
                </>
            }
        </Screen>
    );
}
