import React from "react";
import Home from "./Home";
import Wines from "./Wines";
import Wineries from "./Wineries";
import { createMaterialTopTabNavigator  } from '@react-navigation/material-top-tabs';
import { SafeAreaView } from "react-native-safe-area-context";
import { useTheme } from "native-base";
import { useWindowDimensions } from "react-native";

const Tab = createMaterialTopTabNavigator ();

export default function MainScreens() {
    const theme = useTheme();
    const windowWidth = useWindowDimensions().width;

    const getTabItemWidth = () => {
        if (windowWidth > 600) {
            return 200;
        }
        if (windowWidth < 375) {
            return 125;
        }
        return undefined;
    }

    return (
        <SafeAreaView style={{ flex: 1 }}>
            <Tab.Navigator
                screenOptions={
                    {
                        tabBarIndicatorStyle: { backgroundColor: theme.colors.primary[500], minWidth: 125 },
                        swipeEnabled: false,
                        tabBarStyle: { width: '100%', maxWidth: 1600, marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'rgba(255, 255, 255, 1)', shadowColor: "transparent" },
                        tabBarItemStyle: { backgroundColor: 'rgba(0, 0, 0, 0.0)', minWidth: 125, width: getTabItemWidth() },
                        tabBarLabelStyle: { color: theme.colors.black, minWidth: 125 },
                        tabBarScrollEnabled: windowWidth < 375,
                    }
                }
            >
                <Tab.Screen name="Home" component={Home} options={{ title: "Felfedezés" }}/>
                <Tab.Screen name="Wines" component={Wines} options={{ title: "Borok" }} />
                <Tab.Screen name="Wineries" component={Wineries} options={{ title: "Pincészetek" }}/>
            </Tab.Navigator>
        </SafeAreaView>
    );
}
