import React from "react";
import { useNavigation } from '@react-navigation/native';
import CarouselCard from "../cards/CarouselCard";
import Carousel from "../carousel/Carousel";
import Subtitle from "./Subtitle";
import { Box } from "native-base";

export default function WineryCarousel({wineries, space=5, ml=0, mr=0, mt=0, mb=0}) {
    const navigation = useNavigation();
    if (!wineries) {
        wineries = [];
    }
    if (!Array.isArray(wineries)) {
        wineries = Object.values(wineries);
    }
    if (wineries?.length == 0) {
        return <></>;
    }
    return (
        <Box ml={ml} mr={mr} mt={mt} mb={mb}>
            <Subtitle text={'Pincészetek'} ml={space} mr={space} mt={0} mb={0}/>
            <Carousel
                data={wineries}
                renderItem={({item}) => <CarouselCard image={item.image} title={item.title} onPress={() => navigation.push('Winery', {slug: item.slug})}/>}
                dataKey="id"
                space={space}
            />
        </Box>
    )
}
