import { Box, Heading } from "native-base";
import React from "react";

export default function Title({text, ml=5, mr=5, mt=7, mb=0}) {
    return (
        <Box alignItems="center" ml={ml} mr={mr} mt={mt} mb={mb}>
            <Heading
                fontSize={{
                    base: "30",
                    md: "30",
                    lg: "34",
                    xl: "40"
                }}
                textAlign="left"
                alignSelf="center"
                color="black"
                m={0}
            >
                {text}
            </Heading>
        </Box>
    );
}
