import React from "react";
import { useNavigation } from '@react-navigation/native';
import { IconButton, Center, VStack } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";

export default function HomeButton() {
    const navigation = useNavigation();

    return (
        <Center>
            <VStack space={4} alignItems="center">
                <IconButton size={'md'} variant="solid" mr={3} _icon={{
                    as: MaterialIcons,
                    name: "home"
                }}
                onPress={() => navigation.navigate("MainScreens")}/>
            </VStack>
        </Center>
    );
}


