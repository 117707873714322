import React from "react";
import Screen from "./Screen";
import { useQuery } from "react-query";
import ContentBlocks from "../content_blocks/ContentBlocks";
import { getWine } from "../../services/winery/wineApi";
import { useNavigation } from '@react-navigation/native';
import CenteredImage from "../content_blocks/CenteredImage";
import Title from "../content_blocks/Title";
import ReferenceBox from "../content_blocks/ReferenceBox";
import PropertiesCard from "../cards/PropertiesCard";

export default function Wine({route}) {
    const navigation = useNavigation();
    const { data } = useQuery(['/wine', route.params.winerySlug, route.params.slug, 'getWine'], () => getWine(route.params.winerySlug, route.params.slug), {
        onSuccess: (data) => {
            navigation.setOptions({title: data?.title});
        },
        onError: () => {
            navigation.setOptions({title: 'Hiányzó oldal'});
        }
    });   
    const rows = data != null ? [
        data.color !== null && {
            header: 'Szín',
            data: data.color,
        },
        data.sweetness !== null && {
            header: 'Ízvilág',
            data: data.sweetness,
        },
        data.year !== null && {
            header: 'Évjárat',
            data: data.year,
        },
        data.grapeType !== null && {
            header: 'Szőlőfajta',
            data: data.grapeType,
        },
        data.alcoholContent !== null && {
            header: 'Alkohol tartalom',
            data: data.alcoholContent + ' %',
        },
        data.remainingSugarContent !== null && {
            header: 'Megmaradó cukortartalom',
            data: data.remainingSugarContent + ' g/L',
        },
        data.phValue !== null && {
            header: 'pH érték',
            data: data.phValue + ' pH',
        },
        data.agingPotential !== null && {
            header: 'Öregedési potenciál',
            data: data.agingPotential + ' év',
        },
        data.acidContent !== null && {
            header: 'Savtartalom',
            data: data.acidContent + ' g/L',
        },
        data.allergens !== null && {
            header: 'Allergének',
            data: data.allergens,
        },
        data.bottleCapType !== null && {
            header: 'Kupak fajtája',
            data: data.bottleCapType,
        },
    ].filter(d => d) : [];
    return (
        <Screen>
            {
                data &&
                <>
                    <CenteredImage image={data.image} width={data.imageWidth} height={data.imageHeight} />
                    <Title text={data.title + ' - ' + data.winery.title}/>
                    <ContentBlocks contentBlocks={data.contentBlocks} mb={0}/>
                    <PropertiesCard rows={rows} title={'Jellemzők'}/>
                    <ReferenceBox {...data.winery} typeTitle="Pincészet" mt={5} onPress={() => navigation.push('Winery', {slug: data.winery.slug})}/>
                    <ReferenceBox {...data.place} typeTitle="Település" mb={5} onPress={() => navigation.push('Place', {slug: data.place.slug})}/>
                </>
            }
        </Screen>
    );
}
