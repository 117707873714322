import { Heading } from "native-base";
import React from "react";

export default function Subtitle({text, ml=5, mr=5, mt=7, mb=0}) {
    return (
        <Heading
            fontSize={{
                base: "24",
                md: "24",
                lg: "28",
                xl: "32"
            }}
            textAlign="left"
            color="black"
            ml={ml} mr={mr} mt={mt} mb={mb}
        >
            {text}
        </Heading>
    );
}
