import React from "react";
import { Box } from 'native-base';

export default function CarouselItem({children, last, space}) {
    return (
        <Box marginTop={space} marginBottom={space} marginRight={last ? space : 0} marginLeft={space} rounded="lg" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
            {children}
        </Box>
    );
}
