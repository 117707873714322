import React, { useCallback, useState } from "react";
import { FlatList, View } from 'native-base';
import NavigationButton from './NavigationButton';
import CarouselItem from "./CarouselItem";
import { Platform } from "react-native";

export default function Carousel({data, dataKey, renderItem, space}) {
    const [ref, setRef] = useState();
    const [currentIndex, setCurrentIndex] = useState(0);
    const [viewableItems, setViewableItems] = useState([]);
    
    const getLastIndex = (data, viewableItems) => {
        if (viewableItems[viewableItems.length - 1]?.index == data.length - 1) {
            return data.length - viewableItems.length;
        }
        return data.length - 1;
    }
    const lastIndex = getLastIndex(data, viewableItems);

    const scrollToIndex = (index) => {
        if (index < 0) {
            index = 0;
        }
        if (index > lastIndex) {
            index = lastIndex;
        }
        if (index != currentIndex) {
            setCurrentIndex(index);
            ref?.scrollToIndex({animated: true, index: index});
        }
    }

    const handleVieweableItemsChanged = useCallback(({viewableItems}) => {
        setViewableItems(viewableItems);
    }, []);

    scrollToIndex(currentIndex);
    return (
        <View flexDirection="row" alignItems={"center"}>
            <FlatList
                letterSpacing={5}
                data={data}
                keyExtractor={item => item[dataKey]}
                horizontal={true}
                ref={(ref) => setRef(ref)}
                showsHorizontalScrollIndicator={false}
                renderItem={(props) => <CarouselItem last={props.index == data.length - 1} space={space}>{renderItem(props)}</CarouselItem>}
                viewabilityConfig={{
                    waitForInteraction: false,
                    viewAreaCoveragePercentThreshold: undefined,
                    itemVisiblePercentThreshold: 99
                }}
                onViewableItemsChanged={handleVieweableItemsChanged}
            />
            { Platform.OS == 'web' && currentIndex > 0 && <NavigationButton direction="backwards" onPress={() => scrollToIndex(currentIndex - 1)}/>}
            { Platform.OS == 'web' && currentIndex < lastIndex && <NavigationButton direction="forward" onPress={() => scrollToIndex(currentIndex + 1)}/>}
        </View>
    );
}
