import React from "react";
import { Box, Button, HStack, Image, ScrollView } from 'native-base';
import Paragraph from "../content_blocks/Paragraph";
import { useNavigation } from '@react-navigation/native';

export default function Screen({children, showRegistration = true}) {
    const navigation = useNavigation();
    return (
        <ScrollView bgColor="light.100" nestedScrollEnabled={true}>
            <Box bgColor="white" alignSelf={'center'} width={"100%"} maxWidth={1600}>
                {children}
                <HStack alignItems={'center'} style={{ flex: 1,  justifyContent: 'center', alignItems: 'center' }} flexWrap={'wrap'} mt={5}>
                    <Image source={require('../../assets/logos/mnvh_logo.jpg')} height="40" resizeMode="contain" style={{aspectRatio: 1655/1182}}/>
                    <Image source={require('../../assets/logos/emva_logo.jpg')} height="40" resizeMode="contain" style={{aspectRatio: 1024/428}}/>
                    <Image source={require('../../assets/logos/am_logo.jpg')} height="40" resizeMode="contain" style={{aspectRatio: 397/283}}/>
                </HStack>
                {
                    showRegistration &&
                    <Box alignItems="center" mb={5}>
                        <Paragraph alignSelf={'center'} text="Szeretné viszont látni a katalógusban borait?" />
                        <Button shadow={2} onPress={() => navigation.push('RegistrationForm')} mt={5}>
                            Csatlakozom
                        </Button>
                    </Box>
                }
            </Box>
        </ScrollView>
    );
}
