import React from "react";
import { Box, Flex, Image, Text, useTheme } from 'native-base';
import urls from "../../services/urls";

export default function ParagraphWithImage({source, image, width, height, text, placement='left', space=5, ml=5, mr=5, mt=5, mb=0}) {
    const { colors } = useTheme();
    const imageSource = source ? source : {
        'uri': urls.imageBase + '/' + image,
    }
    const imageComponent = <Image mt={2}
        source={imageSource}
        width="100%"
        maxHeight={height}
        style={{ aspectRatio: width/height }}
        resizeMode= "center"
        alt=" "
    />;
    const textComponent = <Text
        fontSize={{
            base: "18",
            md: "20",
            lg: "22",
            xl: "26"
        }}
        color={colors.black[800]}
    >
        {text}
    </Text>;

    return (
        <Flex 
            ml={ml} mr={mr} mt={mt} mb={mb}
            direction={{
                base: "column",
                md: "column",
                lg: "row",
                xl: "row"
            }}
        >
            <Box 
                width={{
                    base: "100%",
                    md: "100%",
                    lg: "50%",
                    xl: "50%"
                }}
                pr={{
                    base: 0,
                    md: 0,
                    lg: space*0.5,
                    xl: space*0.5,
                }}
            >
                {placement == 'left' ? imageComponent : textComponent}
            </Box>
            <Box
                width={{
                    base: "100%",
                    md: "100%",
                    lg: "50%",
                    xl: "50%"
                }}
                pl={{
                    base: 0,
                    md: 0,
                    lg: space*0.5,
                    xl: space*0.5,
                }}
                pt={{
                    base: space,
                    md: space,
                    lg: 0,
                    xl: 0,
                }}
                >
                {placement == 'left' ? textComponent : imageComponent}
            </Box>
        </Flex>
    );
}
