import React, { useState } from "react";
import { Box, Image } from 'native-base';
import { useNavigation } from '@react-navigation/native';
import Screen from "./Screen";
import SearchResultCard from "../cards/SearchResultCard";
import SearchResultList from "../search/SearchResultList";
import SearchBox from "../search/SearchBox";
import { searchWines } from "../../services/winery/wineApi";
import { useQuery } from "react-query";
import urls from "../../services/urls";

export default function Wines() {
    const navigation = useNavigation();
    const [query, setQuery] = useState('');
    const { data: wines } = useQuery(['/wine/search', query, 'searchWines'], () => searchWines(query));
    return (
        <Screen>
            <Image
                source={{ uri: urls.imageBase + '/borok.jpg'}}
                width="100%"
                height="40"
                resizeMode= "contain"
                alt=" "
                mb="5"
                mt="5"
            />
            <SearchBox space="5" query={query} setQuery={setQuery}/>
            {
                wines?.length > 0
                ?
                <SearchResultList
                    data={wines}
                    dataKey="id"
                    space={5}
                    renderItem={({item}) => <SearchResultCard {...item} onPress={() => navigation.push('Wine', {winerySlug: item.winerySlug, slug: item.slug})}/>}
                />
                :
                <Box p={25}/>
            }
        </Screen>
    );
}
