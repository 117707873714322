import React from "react";
import { Box, Flex, Image, Pressable, useTheme } from 'native-base';
import Subtitle from "./Subtitle";
import Paragraph from "./Paragraph";
import urls from "../../services/urls";

export default function ReferenceBox({typeTitle, source, image, imageWidth, imageHeight, title, description, onPress, space=5, ml=5, mr=5, mt=7, mb=0}) {
    const imageSource = source ? source : {
        'uri': urls.imageBase + '/' + image,
    }
    const { colors } = useTheme();
    return (
        <Box ml={ml} mr={mr} mt={mt} mb={mb}>
            <Subtitle text={typeTitle} mt={0} mb={0} ml={0} mr={0}/>
            <Box bg="white"ml={0} mr={0} mt={space} mb={0} rounded="lg" borderColor="black" style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}} elevation={2}>
                <Pressable onPress={onPress}>
                    <Flex
                        ml={space} mr={space} mt={space} mb={space}
                        direction={{
                            base: "column",
                            md: "column",
                            lg: "row",
                            xl: "row"
                        }}
                    >
                        <Box
                            pr={{
                                base: 0,
                                md: 0,
                                lg: space,
                                xl: space,
                            }}
                        >
                            <Image
                                width={{
                                    base: "100%",
                                    md: "100%",
                                    lg: imageWidth / 2,
                                    xl: imageWidth / 2
                                }}
                                maxHeight={imageHeight /2}
                                source={imageSource}
                                style={{ aspectRatio: imageWidth/imageHeight }}
                                resizeMode="contain"
                                alt=" "
                                mt={0} mb={0} ml={0} mr={0}
                            />
                        </Box>
                        <Box
                            style={{flexGrow: 1, flexShrink: 1}}
                            pl={{
                                base: 0,
                                md: 0,
                                lg: 0,
                                xl: 0,
                            }}
                            pt={{
                                base: space,
                                md: space,
                                lg: 0,
                                xl: 0,
                            }}
                        >
                            <Subtitle
                                fontSize={{
                                    base: "18",
                                    md: "20",
                                    lg: "22",
                                    xl: "26"
                                }}
                                color={colors.black[800]}
                                text={title}
                                mt={0} mb={0} ml={0} mr={0}
                            >
                            </Subtitle>
                            <Paragraph text={description} mb={0} ml={0} mr={0}/>
                        </Box>
                    </Flex>
                </Pressable>
            </Box>
        </Box>
    );
}
