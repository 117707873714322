import React from "react";
import { Image } from 'native-base';
import urls from "../../services/urls";

export default function HeaderImage({source, image, ml=0, mr=0, mt=0, mb=0}) {
    const imageSource = source ? source : {
        'uri': urls.imageBase + '/' + image,
    }
    return (
        <Image
            ml={ml} mr={mr} mt={mt} mb={mb}
            source={imageSource}
            width="100%"
            height={"lg"}
            resizeMode= "cover"
            alt=" "
        />
    );
}
